import React, { useEffect, useState } from "react";
import Nav from "./Nav";
import Footer from "./Footer";
import "./styles/details.scss";
import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Modal,
  Typography,
} from "@mui/material";
import machineImage from "./images/washingMachine.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import axios from "axios";
import { useNavigate, useParams } from "react-router";
import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import ContactPhoneOutlinedIcon from "@mui/icons-material/ContactPhoneOutlined";
import StoreOutlinedIcon from "@mui/icons-material/StoreOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import ApprovalOutlinedIcon from "@mui/icons-material/ApprovalOutlined";
function LocationDetails() {
  document.title = "Speed Queen | Locator";

  const [site, setSite] = useState([]);
  const [currentLink, setCurrentLink] = useState("");
  const [loading, setLoading] = useState(true);
  let params = useParams();
  const siteName = params.name;
  let navigate = useNavigate();

  //modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setCurrentLink(site.layoutLink);

    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setCurrentLink("");
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get("https://nitrous.rt-itservices.co.uk/speedqueen")
      .then((res) => {
        const MainSite = res.data.filter((site) => site.name === siteName);
        MainSite.length > 0 ? setSite(MainSite[0]) : navigate("/locations");
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  }, []);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCA17XufYl3oox1qRT6aXvvM8JqmyvGC1I",
  });

  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const center2 = {
    lat: site?.address?.latitude,
    lng: site?.address?.longitude,
  };

  return (
    <main className="locations__main">
      <div className="nav">
        <Nav page="locator" />
      </div>
      <section className="locations_top_section">
        <h2>{siteName}</h2>
        {!isLoaded && (
          <CircularProgress color="info" style={{ margin: "50px" }} />
        )}
      </section>
      {!loading && isLoaded && (
        <div className="storeDetailMain">
          <div style={{ flex: 1, marginBottom: "20px" }}>
            <div style={{ height: "300px" }}>
              {isLoaded && (
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  zoom={15}
                  center={center2}
                  options={{ streetViewControl: false }}
                >
                  <MarkerF
                    position={{
                      lat: site?.address?.latitude,
                      lng: site?.address?.longitude,
                    }}
                  />
                </GoogleMap>
              )}
            </div>
          </div>
          <div className="locationId" >
          {site.locationId && (
            <p style={{ background:"#e63b11", color:"white",fontSize:"1.3em",padding:"1%" ,width:"100px", borderRadius:"10px" }}>{site.locationId}</p>
          )}
        </div>
          <div
            style={{
              marginBottom: "20px",
              marginTop: "40px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              style={{
                borderRadius: "25px",
                height: "50px",
                marginRight: "20px",
                backgroundColor: "#e63b11",
                marginBottom: "10px",
              }}
              onClick={() => {
                window.open(
                  `https://www.google.com/maps/dir/?api=1&destination=${site?.address?.full} ${site?.address?.postcode}`,
                  "_blank"
                );
              }}
            >
              Get Directions
            </Button>
            <Button
              variant="outlined"
              onClick={() => handleOpen()}
              endIcon={
                <Avatar
                  src={machineImage}
                  style={{ height: "40px", width: "33px", padding: "5px" }}
                />
              }
              style={{
                borderRadius: "25px",
                height: "50px",
                borderColor: "#e63b11",
                color: "#e63b11",
                marginBottom: "10px",
              }}
            >
              See Machines
            </Button>
          </div>
          <Typography
            variant="h4"
            component="h3"
            marginBottom={5}
            sx={{ textAlign: "left" }}
          >
            Store information
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              marginBottom: "50px",
            }}
          >
            <div
              style={{
                borderTop: ".0625rem solid #d3d3d3",
                padding: "10px",
                flex: 1,
                margin: "5px",
              }}
            >
              <Typography
                variant="h6"
                component="h6"
                marginBottom={1}
                marginTop={1}
                sx={{
                  textAlign: "left",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FmdGoodOutlinedIcon
                  color="primary"
                  style={{ marginRight: "20px" }}
                />{" "}
                Address
              </Typography>
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
              >
                <ListItem>
                  <ListItemAvatar>
                    <Avatar style={{ backgroundColor: " rgb(230, 59, 17)" }}>
                      <MapOutlinedIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Address"
                    secondary={site?.address?.full}
                  />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar style={{ backgroundColor: " rgb(230, 59, 17)" }}>
                      <ApprovalOutlinedIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Post Code"
                    secondary={site?.address?.postcode}
                  />
                </ListItem>
              </List>
            </div>

            <div
              style={{
                borderTop: ".0625rem solid #d3d3d3",
                padding: "10px",
                flex: 1,
                margin: "5px",
              }}
            >
              <Typography
                variant="h6"
                component="h6"
                marginBottom={1}
                marginTop={1}
                sx={{
                  textAlign: "left",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <AccessTimeOutlinedIcon
                  color="primary"
                  style={{ marginRight: "20px" }}
                />{" "}
                Opening Hours
              </Typography>
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
              >
                <ListItem>
                  <ListItemAvatar>
                    <Avatar style={{ backgroundColor: " rgb(230, 59, 17)" }}>
                      <StoreOutlinedIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={site?.open ? "Open" : "Closed"}
                    primaryTypographyProps={{
                      color: site?.open ? "green" : "red",
                    }}
                    secondary={
                      site?.open ? "Store is open " : "Store is closed"
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar style={{ backgroundColor: " rgb(230, 59, 17)" }}>
                      <LocalPhoneIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={"Store timing "}
                    secondary={
                      site?.hours?.["24Hours"]
                        ? "24/7 "
                        : `${site?.hours?.open} - ${site?.hours?.close}`
                    }
                  />
                </ListItem>
              </List>
            </div>

            <div
              style={{
                borderTop: ".0625rem solid #d3d3d3",
                padding: "10px",
                flex: 1,
                margin: "5px",
              }}
            >
              <Typography
                variant="h6"
                component="h6"
                marginBottom={1}
                marginTop={1}
                sx={{
                  textAlign: "left",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ContactPhoneOutlinedIcon
                  color="primary"
                  style={{ marginRight: "20px" }}
                />{" "}
                Contact Information
              </Typography>
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
              >
                <ListItem
                  onClick={() => {
                    document.location.href = `mailto:${site?.contact?.email}`;
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <ListItemAvatar>
                    <Avatar style={{ backgroundColor: " rgb(230, 59, 17)" }}>
                      <EmailIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Email"
                    secondary={site?.contact?.email}
                  />
                </ListItem>
                <ListItem
                  onClick={() => {
                    document.location.href = `tel:${site?.contact?.phone}`;
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <ListItemAvatar>
                    <Avatar style={{ backgroundColor: " rgb(230, 59, 17)" }}>
                      <LocalPhoneIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Phone"
                    secondary={site?.contact?.phone}
                  />
                </ListItem>
              </List>
            </div>
          </div>
        </div>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ textAlign: "center" }}
          >
            See machines and create an alert
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <iframe
              src={currentLink}
              title="Machines"
              style={{ maxHeight: "65vh" }}
            />
          </Typography>
        </Box>
      </Modal>
      <Footer />
    </main>
  );
}

export default LocationDetails;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  height: "80vh",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 4,
  "@media(max-width: 600px)": {
    width: "95vw",
    height: "90vh",
  },
  "@media(min-width: 601px) and (max-width: 900px)": {
    width: "90vw",
    maxHeight: "80vh",
  },
};
