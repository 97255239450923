// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.storeDetailMain {
  margin: 1% 15% 1% 15%;
  overflow: hidden;
}
.storeDetailMain .locationId {
  display: flex;
  justify-content: center;
}
@media (max-width: 850px) {
  .storeDetailMain {
    margin: 1% 2% 1% 2%;
  }
}
@media (max-width: 1000px) {
  .storeDetailMain {
    margin: 1% 2% 1% 2%;
  }
}

iframe {
  width: 100%;
  height: 780px;
  border: 2px solid #e63b11;
}
@media (max-width: 690px) {
  iframe {
    height: 650px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/styles/details.scss"],"names":[],"mappings":"AAEA;EACE,qBAAA;EACA,gBAAA;AADF;AAEE;EACE,aAAA;EACA,uBAAA;AAAJ;AAGE;EARF;IASI,mBAAA;EAAF;AACF;AACE;EAXF;IAYI,mBAAA;EAEF;AACF;;AACA;EACE,WAAA;EACA,aAAA;EACA,yBAAA;AAEF;AADE;EAJF;IAKI,aAAA;EAIF;AACF","sourcesContent":["@import \"./variables.scss\";\n\n.storeDetailMain {\n  margin: 1% 15% 1% 15%;\n  overflow: hidden;\n  .locationId{\n    display: flex;\n    justify-content: center;\n    \n  }\n  @media (max-width: $tabletMax) {\n    margin: 1% 2% 1% 2%;\n  }\n  @media (max-width: $landsTabletMax) {\n    margin: 1% 2% 1% 2%;\n  }\n}\n\niframe {\n  width: 100%;\n  height: 780px;\n  border: 2px solid $orange;\n  @media (max-width: $mobile) {\n    height: 650px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
