import "../styles/faq.scss";
import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Tutorial from "../images/tutorial.mp4";
import { Link } from "react-router-dom";

export default function Services() {
  const [showId, setShowId] = useState();

  const handleShow = (id) => {
    if (showId === id) {
      setShowId(0);
    } else {
      setShowId(id);
    }
  };

  return (
    <main className="services__tab">
      <section style={{backgroundColor:'white'}} className="tab_section" >
        <div className={showId == 1? 'tab__title_open':'tab__title_close'} onClick={() => handleShow(1)}>
          <h3>How much does it cost?</h3>
          {showId === 1 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </div>
        {showId === 1 ? (
          <div className="open__tab">
            <strong>To wash</strong>
            <ul>
              <li>8kg wash - £5.50</li>
              <li>14kg wash - £9</li>
              <li>18kg wash - £11</li>
            </ul>
            <strong>To dry</strong>
            <ul>
              <li>£3.50 per 15 min</li>
            </ul>
          </div>
        ) : (
          <></>
        )}
      </section>
      <section className="tab_section">
        <div className={showId == 2? 'tab__title_open':'tab__title_close'} onClick={() => handleShow(2)}>
          <h3>What temperature will my clothes be washed at?</h3>
          {showId === 2 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </div>
        {showId === 2 ? (
          <div className="open__tab">
            <p>
              At speed queen we don't use hot water. Hot water damages the
              fibres of your garment, causes colours to run and shrinks clothes.
              At Speed Queen we ozonise the water which gets the dirt and
              bacteria out of the fibres leaving them soft, clean, fresh and
              fully sanitised.
            </p>
          </div>
        ) : (
          <></>
        )}
      </section>
      <section className="tab_section">
        <div className={showId == 3 ?'tab__title_open':'tab__title_close'} onClick={() => handleShow(3)}>
          <h3>What temperature are the dryers set at?</h3>
          {showId === 3 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </div>
        {showId === 3 ? (
          <div className="open__tab">
            <p>High - 88 Degrees centigrade</p>
            <p>Medium - 82 Degrees centigrade</p>
            <p>Low - 71 Degrees centigrade</p>
            <p>Delicates - 54 Degrees centigrade</p>
          </div>
        ) : (
          <></>
        )}
      </section>
      <section className="tab_section">
        <div className={showId == 4? 'tab__title_open':'tab__title_close'} onClick={() => handleShow(4)}>
          <h3>Is detergent included in my wash?</h3>
          {showId === 4 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </div>
        {showId === 4 ? (
          <div className="open__tab">
            <p>Yes all machines are self loaded with non-bio detergent.</p>
          </div>
        ) : (
          <></>
        )}
      </section>
      <section className="tab_section">
        <div className={showId == 5? 'tab__title_open':'tab__title_close'} onClick={() => handleShow(5)}>
          <h3>How do I use the washing machine?</h3>
          {showId === 5 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </div>
        {showId === 5 ? (
          <div className="open__tab">
            <video width="80%" controls>
              <source
                src={Tutorial}
                type="video/mp4"
                style={{ aspectRatio: "16/9" }}
              />
            </video>
          </div>
        ) : (
          <></>
        )}
      </section>
      <section className="tab_section">
        <div className={showId == 6? 'tab__title_open':'tab__title_close'} onClick={() => handleShow(6)}>
          <h3>How do I use the dryer?</h3>
          {showId === 6 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </div>
        {showId === 6 ? (
          <div className="open__tab">
            <video width="80%" controls>
              <source
                src={Tutorial}
                type="video/mp4"
                style={{ aspectRatio: "16/9" }}
              />
            </video>
          </div>
        ) : (
          <></>
        )}
      </section>
      <section className="tab_section">
        <div className={showId == 7? 'tab__title_open':'tab__title_close'} onClick={() => handleShow(7)}>
          <h3>What happens if the machines aren't working properly?</h3>
          {showId === 7 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </div>
        {showId === 7 ? (
          <div className="open__tab">
            <p>
              If you are having issues with any of the machines please call our
              service team on (+44) 0141 530 3138 or drop us a direct message on
              any of our socials and we will be in touch! You can also fill{" "}
              <Link to="/request-help" className="form_link">this form</Link>.
            </p>
          </div>
        ) : (
          <></>
        )}
      </section>
      <section className="tab_section">
        <div className={showId == 8? 'tab__title_open':'tab__title_close'} onClick={() => handleShow(8)}>
          <h3>How long are the cycles?</h3>
          {showId === 8 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </div>
        {showId === 8 ? (
          <div className="open__tab">
            <p>
              The average cycle is 35 minutes. This may change depending on size
              of the wash load.
            </p>
          </div>
        ) : (
          <></>
        )}
      </section>
      <section className="tab_section">
        <div className={showId == 9? 'tab__title_open':'tab__title_close'} onClick={() => handleShow(9)}>
          <h3>How much water is used?</h3>
          {showId === 9 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </div>
        {showId === 9 ? (
          <div className="open__tab">
            <p>
              The amount of water used is dependent on the load. The machine
              calculates this in the prewash cycle, there is then a wash cycle
              and 3 rinse cycles.
            </p>
          </div>
        ) : (
          <></>
        )}
      </section>
      <section className="tab_section">
        <div className={showId == 10? 'tab__title_open':'tab__title_close'} onClick={() => handleShow(10)}>
          <h3>What washer should I use for my duvet</h3>
          {showId === 10 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </div>
        {showId === 10 ? (
          <div className="open__tab">
            <p>
              Depending on the size of duvet. Make sure there is space between
              the top of the duvet and drum. 18kg is best for large duvets.
            </p>
          </div>
        ) : (
          <></>
        )}
      </section>
      <section className="tab_section">
        <div className={showId == 11? 'tab__title_open':'tab__title_close'} onClick={() => handleShow(11)}>
          <h3>What is the capacity of your washers</h3>
          {showId === 11 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </div>
        {showId === 11 ? (
          <div className="open__tab">
            <p>We have 8kg, 14kg and 18kg</p>
          </div>
        ) : (
          <></>
        )}
      </section>
      <section className="tab_section">
        <div className={showId == 12? 'tab__title_open':'tab__title_close'} onClick={() => handleShow(12)}>
          <h3>What are the opening hours</h3>
          {showId === 12 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </div>
        {showId === 12 ? (
          <div className="open__tab">
            <p>We are open from 7am to 10pm everyday in all our locations. Of which the last wash payment is accepted at 9:59pm. The doors into the launderette will lock at 10.30pm. Please ensure you have left the facility before this time.</p>
          </div>
        ) : (
          <></>
        )}
      </section>
      <section className="tab_section">
        <div className={showId == 13? 'tab__title_open':'tab__title_close'} onClick={() => handleShow(13)}>
          <h3>where are the launderies located</h3>
          {showId === 13 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </div>
        {showId === 13 ? (
          <div className="open__tab">
            <p>Locator - <a href='https://my.atlistmaps.com/map/849a2822-1ff6-44d4-9a5a-13e899d2e792'>https://my.atlistmaps.com/map/849a2822-1ff6-44d4-9a5a-13e899d2e792</a></p>
          </div>
        ) : (
          <></>
        )}
      </section>
      <section className="tab_section">
        <div className={showId == 14? 'tab__title_open':'tab__title_close'} onClick={() => handleShow(14)}>
          <h3>How can I pay</h3>
          {showId === 14 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </div>
        {showId === 14 ? (
          <div className="open__tab">
            <p>The only methods of payment that we accept is contactless card payment and payment through the Speed Queen app.</p>
          </div>
        ) : (
          <></>
        )}
      </section>
      <section className="tab_section">
        <div className={showId == 15? 'tab__title_open':'tab__title_close'} onClick={() => handleShow(15)}>
          <h3>Are all your washing machines cold water washes</h3>
          {showId === 15 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </div>
        {showId === 15 ? (
          <div className="open__tab">
            <p>Yes, all our machines use Ozone cold water wash technology. This means you will get the same results as a warm water wash and removes 99.9% of bacteria. Similar to machines used for hospital laundry.</p>
          </div>
        ) : (
          <></>
        )}
      </section>
    </main>
  );
}
