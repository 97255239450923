import { Avatar, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import CircleIcon from "@mui/icons-material/Circle";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import NearMeIcon from "@mui/icons-material/NearMe";
import { useNavigate } from "react-router-dom";
import machineImage from "./images/washingMachine.png";
import LocationOnIcon from "@mui/icons-material/LocationOn";
export default function SingleStore({
  site,
  keyValue,
  setCurrentLink,
  setOpen,
  setActiveSingleStore,
  activeSingleStore,
}) {
  const { postcode, full: address } = site.address;

  const { name, open, distanceToUser, layoutLink } = site;

  const { close: closeTime, open: openTime } = site.hours;

  const handleOpen = () => {
    setCurrentLink(layoutLink);
    setOpen(true);
  };

  return (
    <div
      className="SingleStoreContainer"
      style={
        activeSingleStore === keyValue ? { backgroundColor: "#f6f6f6" } : {}
      }
      onClick={() => {
        setActiveSingleStore(keyValue);
      }}
    >
      <Typography variant="body1" component="span" style={{ margin: "10px" }}>
        {keyValue + 1 || "N/A"}
      </Typography>
      <div style={{ width: "100%", padding: "10px" }}>
        <div
          style={{
            justifyContent: "space-between",
            display: "flex",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <div>
            <Typography variant="h5" component="span">
              {name || "N/A"}
            </Typography>
          </div>
          <span>{distanceToUser && `${distanceToUser} mi`} </span>
        </div>
        <p style={{ margin: 0, display: "flex", alignItems: "center" }}>
          <LocationOnIcon color="error" /> {address || "N/A"},{" "}
          {postcode || "N/A"}
        </p>
        <div
          style={{
            justifyContent: "left",
            display: "flex",
            alignItems: "center",
          }}
        >
          <CircleIcon
            color={open ? "success" : "error"}
            fontSize="small"
            style={{ width: "12px", marginRight: "10px" }}
          />
          <p> {open ? "Open" : "Closed"}</p>
        </div>
        <div>
          <p style={{ marginTop: "0" }}>
            {site.hours["24Hours"] === true
              ? "Open: 24/7"
              : `Open: ${openTime} - ${closeTime}`}
          </p>
        </div>
        <div>
          {site.locationId && (
            <p style={{ background:"#e63b11", color:"white",fontSize:"1.5em",padding:"5px 1.5%" ,width:"100px", borderRadius:"10px" }}>{site.locationId}</p>
          )}
        </div>
        <div
          style={{
            marginBottom: "20px",
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <Button
            variant="contained"
            style={{
              borderRadius: "25px",
              height: "50px",
              marginRight: "10px",
              backgroundColor: "#e63b11",
              marginBottom: "10px",
            }}
            endIcon={<NearMeIcon />}
            onClick={() => {
              window.open(
                `https://www.google.com/maps/dir/?api=1&destination=${address} ${postcode}`,
                "_blank"
              );
            }}
          >
            Get Directions
          </Button>

          <Button
            variant="outlined"
            onClick={() => handleOpen()}
            endIcon={
              <Avatar
                src={machineImage}
                style={{ height: "40px", width: "33px", padding: "5px" }}
              />
            }
            style={{
              borderRadius: "25px",
              height: "50px",
              borderColor: "#e63b11",
              color: "#e63b11",
              marginBottom: "10px",
              marginRight: "10px",
            }}
          >
            See Machines
          </Button>
          <Button
            variant="contained"
            style={{
              borderRadius: "25px",
              height: "50px",
              marginRight: "20px",
              backgroundColor: "#e63b11",
              marginBottom: "10px",
              textDecoration: "underline",
            }}
          >
            <a href={`store/${name}`} style={{ color: "white" }}>
              {" "}
              Details page{" "}
            </a>
          </Button>
        </div>
      </div>
    </div>
  );
}
