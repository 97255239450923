import { useState, useEffect } from "react";
import { Rating, TextField, Button } from "@mui/material";
import { BsArrowLeft } from "react-icons/bs";
import Nav from "./Nav";
import "./styles/review.scss";
import { useParams } from "react-router-dom";
import axios from "axios";

export default function Review({}) {
  const { id } = useParams();
  const [isFormShowed, setIsFormShowed] = useState(false);
  const [sites, setSites] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [formInfo, setFormInfo] = useState({
    name: "",
    email: "",
    site: id,
    rating: Number,
    review: "",
  });

  const googleReviewPages = {
    SQGS01: "https://g.page/r/CRAYQETV7G4OEB0/review",
    SQGS02:
      "https://search.google.com/local/writereview?placeid=ChIJN82zaGATiEgR3_4IgdZPLFc",
    SQGS03:
      "https://search.google.com/local/writereview?placeid=ChIJydMlwLdBiEgR07qvZZZyVgE",
    SQGS04:
      "https://search.google.com/local/writereview?placeid=ChIJ0b67VMhHiEgRrUenRNFKbbw",
    SQGS05:
      "https://search.google.com/local/writereview?placeid=ChIJ0ZlapG9DiEgRNyJ8XpElmVk",
  };
  useEffect(() => {
    axios
      .get("https://nitrous.rt-itservices.co.uk/speedqueen")
      .then((res) => {
        setSites(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    setFormInfo({ ...formInfo, site: id });
  }, [id]);

  const handleInfoChange = (event) => {
    setFormInfo({ ...formInfo, [event.target.name]: event.target.value });
  };
  const handleFormDisplay = () => {
    setFormInfo({
      name: "",
      email: "",
      site: id,
      rating: Number,
      review: "",
    });
    setErrorMessage(false);
    setIsFormShowed(!isFormShowed);
  };

  const handleRedirect = () => {
    if (formInfo.rating > 3) {
      window.location.href = googleReviewPages[id];
    } else {
      setIsFormShowed(true);
    }
  };

  const handleRatingChange = (event, newValue) => {
    setFormInfo((prevState) => ({
      ...prevState,
      rating: Number(newValue),
    }));
  };

  const handleFormSubmit = () => {
    let regex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    if (formInfo.email.match(regex)) {
      if (
        formInfo.email.trim() !== "" &&
        formInfo.review.trim() !== "" &&
        formInfo.name.trim() !== ""
      ) {
        axios
          .post(
            "https://nitrous.rt-itservices.co.uk/speedqueen/review",
            formInfo
          )
          .then((res) => {
            setIsSubmitted(true);
          })
          .catch((err) => {
            setErrorMessage(
              "An error occured while submitting your review. Please try again later."
            );
          });
      } else {
        setErrorMessage("Please fill all the required fields!");
      }
    } else {
      setErrorMessage("Please enter a valid email address!");
    }
  };

  return (
    <>
      <div className="nav">
        <Nav page="locator" />
      </div>
      <div className="container">
        {!isFormShowed ? (
          <div className="starRating">
            <h2> How was your experience?</h2>
            <Rating
              className="rating"
              name="half-rating"
              value={formInfo.rating}
              precision={1}
              onChange={handleRatingChange}
            />
            {formInfo.rating > 0 && (
              <button onClick={handleRedirect} className="btn">
                Submit
              </button>
            )}
          </div>
        ) : !isSubmitted ? (
          <div className="reviewForm">
            <div className="backButton" onClick={handleFormDisplay}>
              <BsArrowLeft size="2em" />
            </div>
            <h2>Help us become better!</h2>
            <section className="form">
              <label htmlFor="name">Full Name:</label>
              <TextField
                type="text"
                name="name"
                onChange={handleInfoChange}
                value={formInfo.name}
                variant="standard"
              />
              <label htmlFor="email">Email:</label>
              <TextField
                type="email"
                name="email"
                onChange={handleInfoChange}
                value={formInfo.email}
                variant="standard"
              />
              <label htmlFor="site">Site</label>
              <select
                className="siteSelection"
                name="site"
                onChange={handleInfoChange}
                value={formInfo.site}
              >
                {sites.map((site, index) => (
                  <option key={index} value={site.locationId}>
                    {site.name} ({site.locationId})
                  </option>
                ))}
              </select>
              <label htmlFor="review">Describe your situation:</label>
              <textarea
                name="review"
                cols={40}
                rows={5}
                onChange={handleInfoChange}
                value={formInfo.review}
              />
              {errorMessage && <p className="error">{errorMessage}</p>}
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button className="btn" onClick={handleFormSubmit}>
                  Send
                </button>
              </div>
            </section>
          </div>
        ) : (
          <div>
            <h2>Thank you for your review!</h2>
          </div>
        )}
      </div>
    </>
  );
}
