import { MarkerF } from "@react-google-maps/api";
import MapMarkerImg from "./images/mapMarker2.png";
import { useNavigate } from "react-router-dom";

export default function MapMarker(props) {
  const { activeSingleStore, markerKey } = props;
  const { name } = props.data;

  let navigate = useNavigate();

  return (
    <div>
      <MarkerF
        icon={{
          url: MapMarkerImg,
          scaledSize:
            activeSingleStore === markerKey
              ? new window.google.maps.Size(60, 80)
              : new window.google.maps.Size(45, 60),
        }}
        position={{
          lat: props.data.address.latitude,
          lng: props.data.address.longitude,
        }}
        onClick={() => {
          props.update(props.data.locationId);
          navigate(`/store/${name}`);
        }}
      />
    </div>
  );
}
