import "../styles/faq.scss";
import { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

export default function Laund() {
  const [showId, setShowId] = useState();

  const handleShow = (id) => {
    if (showId === id) {
      setShowId(0);
    } else {
      setShowId(id);
    }
  };

  return (
    <main className="services__tab">
      <section className="tab_section">
        <div
          className={showId == 1 ? "tab__title_open" : "tab__title_close"}
          onClick={() => handleShow(1)}
        >
          <h3>What are the opening hours?</h3>
          {showId === 1 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </div>
        {showId === 1 ? (
          <div className="open__tab">
            <p>We are open 24h 7 days a week</p>
          </div>
        ) : (
          <></>
        )}
      </section>
      <section className="tab_section">
        <div
          className={showId == 2 ? "tab__title_open" : "tab__title_close"}
          onClick={() => handleShow(2)}
        >
          <h3>What is the refund policy?</h3>
          {showId === 2 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </div>
        {showId === 2 ? (
          <div className="open__tab">
            <p>
              All refunds will be in the form of store credit, which is loaded
              onto your card balance.
            </p>
          </div>
        ) : (
          <></>
        )}
      </section>
      <section className="tab_section">
        <div
          className={showId == 3 ? "tab__title_open" : "tab__title_close"}
          onClick={() => handleShow(3)}
        >
          <h3>Where are the launderettes located?</h3>
          {showId === 3 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </div>
        {showId === 3 ? (
          <div className="open__tab">
            <p>Check out the locator at the top menu bar of our website.</p>
          </div>
        ) : (
          <></>
        )}
      </section>
    </main>
  );
}
