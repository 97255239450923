import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import How from "./components/How";
import Locator from "./components/Locator";
import GetApp from "./components/GetApp";
import Faq from "./components/Faq";
import Contact from "./components/Contact";
import RequestHelp from "./components/RequestHelp";
import Help from "./components/Help";
import Review from "./components/Review";
import Investors from "./components/Investors";
import Festive from "./components/Festive";
import LocationDetails from "./components/LocationDetails";
import axios from "axios";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="how-it-works" element={<How />} />
          <Route path="locations" element={<Locator />} />
          <Route path="store/:name" element={<LocationDetails />} />
          <Route path="get-the-app" element={<GetApp />} />
          <Route path="faq" element={<Faq />} />
          {/* <Route path="festive" element={<Festive/>}/> */}
          <Route path="festive" element={<Home />} />
          <Route path="contact" element={<Contact />} />
          <Route path="request-help" element={<RequestHelp />} />
          <Route path="help" element={<Help />} />
          <Route path="review/:id" element={<Review />} />
          <Route path="investors" element={<Investors />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
