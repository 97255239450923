import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  DistanceMatrixService,
  GoogleMap,
  useJsApiLoader,
} from "@react-google-maps/api";
import Nav from "./Nav";
import Footer from "./Footer";
import "./styles/locator.scss";
import StoreImg from "./images/locator.png";
import MapMarkerImg from "./images/mapMarker2.png";
import NearMeOutlinedIcon from "@mui/icons-material/NearMeOutlined";
import MapMarker from "./MapMarker";
//modal
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Button, CircularProgress, Stack, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import MyLocationOutlinedIcon from "@mui/icons-material/MyLocationOutlined";
import SingleStore from "./SingleStore";
import haversine from "haversine-distance";
let PostcodesIO = require("postcodesio-client");

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  height: "80vh",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 4,
  "@media(max-width: 600px)": {
    width: "95vw",
    height: "90vh",
  },
  "@media(min-width: 601px) and (max-width: 900px)": {
    width: "90vw",
    maxHeight: "80vh",
  },
};

export default function Locator() {
  document.title = "Speed Queen | Locator";

  const [sites, setSites] = useState([]);
  const [updatedSites, setUpdatedSites] = useState([]);
  const [showInfoBox, setShowInfoBox] = useState(false);
  const [infoData, setInfoData] = useState({});
  const [currentLink, setCurrentLink] = useState("");
  const [userPostCode, setUserPostCode] = useState("");
  const [activeSingleStore, setActiveSingleStore] = useState(null);
  const [loading, setLoading] = useState(false);
  //modal
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setCurrentLink("");
  };

  let postcodes = new PostcodesIO("https://api.postcodes.io", {
    headers: { "User-Agent": "MyAwesomeApp/1.0.0" }, // default {} - extra headers
  });

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCA17XufYl3oox1qRT6aXvvM8JqmyvGC1I",
  });

  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const center2 = {
    lat: 55.88072430176191,
    lng: -4.113365,
  };

  useEffect(() => {
    axios
      .get("https://nitrous.rt-itservices.co.uk/speedqueen")
      .then((res) => {
        setSites(res.data);
        console.log("locations data:", res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [isLoaded]);

  const reset = () => {
    setShowInfoBox(false);
    setInfoData({});
  };

  const infoBoxUpdate = async (sqId) => {
    if (sqId == infoData.locationId) {
      reset();
    } else {
      const idx = sites.findIndex((a) => a.locationId == sqId);
      setInfoData(sites[idx]);
      setShowInfoBox(true);
    }
  };

  const successCallback = async (position) => {
    const { latitude, longitude } = position.coords || null;
    const service = new window.google.maps.DistanceMatrixService();
    let stores = [];
    for (let site of sites) {
      const { latitude: siteLatitude, longitude: sitelongitude } = site.address;
      const userPosition = { lat: latitude, lng: longitude };
      const sitePosition = { lat: siteLatitude, lng: sitelongitude };

      const result = await service.getDistanceMatrix({
        destinations: [userPosition],
        origins: [sitePosition],
        travelMode: "DRIVING",
        unitSystem: 1.0,
      });

      site.distanceToUser = (
        result.rows[0]?.elements[0]?.distance?.value * 0.000621371
      ).toFixed(2);
      stores = [...stores, site];
    }

    stores.sort(function (a, b) {
      return a.distanceToUser - b.distanceToUser;
    });
    setUpdatedSites(stores);
    setLoading(false);
  };

  const errorCallback = (error) => {
    setLoading(false);
    console.log("Error fallback:", error);
    window.alert(
      "Sorry, we are unable to get your location at this time. Please check your location setting"
    );
  };

  const searchWithPostCode = async () => {
    if (userPostCode !== "") {
      try {
        const result = await postcodes.lookup(userPostCode);
        if (result) {
          const { latitude, longitude } = result || undefined;

          successCallback({
            coords: {
              latitude: latitude,
              longitude: longitude,
            },
          });
        } else {
          window.alert(
            "Unable to fetch your postcode location,Please make sure you have entered the correct postcode"
          );
        }
      } catch (e) {
        window.alert("Unable to fetch your postcode location");
      }
    }
  };

  return isLoaded ? (
    <main className="locations__main">
      <div className="nav">
        <Nav page="locator" />
      </div>
      <section className="locations_top_section">
        <h2>Our Locations</h2>
      </section>

      <section className="locator-main">
        <div className="searchStore">
          <div>
            <div className="locaterFindHeading">
              <SearchIcon fontSize="large" style={{ marginRight: "10px" }} />
              <Typography variant="h5" component="h2">
                Find a store
              </Typography>
            </div>
            <div className="searchBtn">
              <div style={{ display: "flex" }}>
                <TextField
                  id="outlined-basic"
                  label="Enter your post code"
                  value={userPostCode}
                  onChange={(e) => setUserPostCode(e.target.value)}
                  variant="outlined"
                  fullWidth
                />
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    searchWithPostCode();
                  }}
                  style={{
                    marginLeft: "20px",
                    width: "200px",
                    backgroundColor: "#e63b11",
                  }}
                >
                  Search
                </Button>
              </div>
              <div className="findWIthLocation">
                <Button
                  variant="contained"
                  onClick={() => {
                    setLoading(true);
                    navigator.geolocation.getCurrentPosition(
                      successCallback,
                      errorCallback
                    );
                  }}
                  endIcon={<MyLocationOutlinedIcon />}
                  style={{
                    padding: "7.5px 15px 7.5px 15px",
                    borderRadius: "30px",
                    backgroundColor: "#e63b11",
                  }}
                >
                  Find a store near me
                </Button>
              </div>
            </div>
            {updatedSites.length > 0 && (
              <div>
                <Typography variant="body2" component="p">
                  {updatedSites.length || "N/A"} stores near your location
                </Typography>
              </div>
            )}

            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "50px",
                }}
              >
                <CircularProgress color="info" />
              </div>
            ) : updatedSites.length > 0 ? (
              <div className="EachStoreContainer">
                {updatedSites &&
                  updatedSites.map((value, index) => (
                    <SingleStore
                      key={index}
                      site={value}
                      keyValue={index}
                      setCurrentLink={setCurrentLink}
                      setOpen={setOpen}
                      setActiveSingleStore={setActiveSingleStore}
                      activeSingleStore={activeSingleStore}
                    />
                  ))}
              </div>
            ) : (
              <div className="EachStoreContainer">
                {sites &&
                  sites.map((value, index) => (
                    <SingleStore
                      key={index}
                      site={value}
                      keyValue={index}
                      setCurrentLink={setCurrentLink}
                      setOpen={setOpen}
                      setActiveSingleStore={setActiveSingleStore}
                      activeSingleStore={activeSingleStore}
                    />
                  ))}
              </div>
            )}
          </div>
        </div>

        <div className="mapContainer">
          <div className="map">
            <GoogleMap
              mapContainerStyle={containerStyle}
              zoom={11}
              center={center2}
              options={{ streetViewControl: false }}
              onClick={() => {
                reset();
              }}
            >
              {updatedSites.length === 0
                ? sites.map(function (d, index) {
                    return (
                      <MapMarker
                        key={index}
                        markerKey={index}
                        data={d}
                        update={infoBoxUpdate}
                        activeSingleStore={activeSingleStore}
                      />
                    );
                  })
                : updatedSites.map(function (d, index) {
                    return (
                      <MapMarker
                        key={index}
                        markerKey={index}
                        data={d}
                        update={infoBoxUpdate}
                        activeSingleStore={activeSingleStore}
                      />
                    );
                  })}
            </GoogleMap>
          </div>
        </div>
      </section>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ textAlign: "center" }}
          >
            See machines and create an alert
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <iframe
              src={currentLink}
              title="Machines"
              style={{ maxHeight: "65vh" }}
            />
          </Typography>
        </Box>
      </Modal>
      <Footer />
    </main>
  ) : (
    <></>
  );
}
