import { useEffect, useState } from "react";
import axios from "axios";
import Footer from "./Footer";
import Nav from "./Nav";
import ContactImg from "./images/contactImg.png";
import "./styles/contact.scss";
import { Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import moment from "moment";

export default function Contact() {
  document.title = "Speed Queen | Contact";
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [reason, setReason] = useState("");
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [warningAlert, setWarningAlert] = useState(false);
  const [response, setResponse] = useState("");
  const [site, setSite] = useState("");
  const navigate = useNavigate();
  const [sites, setSites] = useState([]);

  const submitForm = async () => {
    document.getElementById("submit_button").style.display = "none";
    let regex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    if (email.match(regex)) {
      if (
        reason === "" ||
        reason === "Choose an option" ||
        message === "" ||
        site === "" ||
        site === "Choose a site"
      ) {
        setSeverity("warning");
        setResponse(
          "Please ensure that all the required fields are not left blank!"
        );
        setWarningAlert(true);
        setTimeout(() => {
          setSeverity("");
          setResponse("");
          setWarningAlert(false);
          document.getElementById(
            "submit_button"
          ).style = `display: flex; flex-direction: column; align-items: center; justify-content: center;`;
        }, 3500);
      } else {
        let data = {
          name: name,
          lastName: lastName,
          email: email,
          reason: reason,
          message: message,
          site: site,
        };
        await axios
          .post("https://nitrous.rt-itservices.co.uk/speedqueen/contact", data)
          .then((res) => {
            setSeverity("success");
            setResponse("Form sent, thanks for getting in touch");
            setWarningAlert(true);
            setTimeout(() => {
              setSeverity("");
              setResponse("");
              setWarningAlert(false);
              document.getElementById(
                "submit_button"
              ).style = `display: flex; flex-direction: column; align-items: center; justify-content: center;`;
              setReason("Choose an option");
              setSite("Choose a site");
              setName("");
              setLastName("");
              setMessage("");
              setEmail("");
              document.querySelectorAll("select").selectedIndex = 0;
            }, 3500);
          })
          .catch((err) => {
            setSeverity("error");
            setResponse("Something went wrong, please try again");
            setWarningAlert(true);
            setTimeout(() => {
              setSeverity("");
              setResponse("");
              setWarningAlert(false);
              document.getElementById(
                "submit_button"
              ).style = `display: flex; flex-direction: column; align-items: center; justify-content: center;`;
            }, 3500);
          });
      }
    } else {
      setSeverity("warning");
      setResponse("Email formated wrong, please try again");
      setWarningAlert(true);
      setTimeout(() => {
        setSeverity("");
        setResponse("");
        setWarningAlert(false);
        document.getElementById(
          "submit_button"
        ).style = `display: flex; flex-direction: column; align-items: center; justify-content: center;`;
      }, 3500);
    }
  };

  useEffect(() => {
    axios
      .get("https://nitrous.rt-itservices.co.uk/speedqueen/")
      .then((res) => {
        setSites(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleChat = () => {
    var currentTime = moment();
    var morning = moment("08:30 am", "HH:mm a");
    var night = moment("05:00 pm", "HH:mm a");
    const phone = currentTime.isBetween(morning, night);
    if (phone) {
      document.location.href = "tel:01415303138";
    } else {
      window.open("https://wa.me/message/CJU6ZURKMONWL1", "_blank");
    }
  };

  return (
    <main className="contact__main">
      <Nav page="contact" />
      <div className="contact_heading">
        <img src={ContactImg} />
        <div>
          <h2>Contact Us</h2>
          <p>
            Do you have any questions, requests or feedback that you need us to
            help with. Please get in touch and our team will be on hand to
            assist you right away.
          </p>
          <p>
            We are open and appreciative of feedback, so let us know is there
            anything we can do better!
          </p>
          {/* <p>Our customer support hours will be varied throughout the festive period - <a href="/festive">SEE HOURS</a></p> */}
          <button onClick={() => navigate("/request-help")} className="button">
            Had an issue?
          </button>
          <button className="button" onClick={handleChat}>
            Chat with us
          </button>
        </div>
      </div>

      <div className="contact_form">
        <div className="contact__fields">
          <div className="contact__options">
            <h3>Let us know what your query is about.</h3>
            <hr />
            <br></br>
            <label id="selectLabel">Select from one of the following *</label>
            <br />
            <select
              onChange={(e) => {
                setReason(e.target.value);
              }}
              value={reason}
            >
              <option>Choose an option</option>
              <option>Request A Refund</option>
              <option>Request A Receipt</option>
              <option>Experienced Issues</option>
              <option>Something Else</option>
              <option>Investor Enquiry</option>
            </select>
          </div>
          <br></br>
          <div className="contact__text">
            <div className="site__options">
              <label id="selectLabel">Site *</label>
              <br />
              <select
                onChange={(e) => {
                  setSite(e.target.value);
                }}
                value={site}
              >
                <option selected>Choose a site</option>
                {sites.map(function (d, i) {
                  return (
                    <option>
                      {d.name} ({d.address.postcode})
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="contact__name">
              <div>
                <label>First Name</label>
                <br />
                <input
                  type="text"
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  value={name}
                />
              </div>
              <div>
                <label>Last Name</label>
                <br />
                <input
                  type="text"
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                  value={lastName}
                />
              </div>
            </div>
            <br />
            <label>Email *</label>
            <input
              type="email"
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              value={email}
            />
            <br />
            <span>Message</span>
            <textarea
              rows={5}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
              value={message}
            />
          </div>
        </div>
        <button
          id="submit_button"
          onClick={() => {
            submitForm();
          }}
        >
          Send
        </button>
        {warningAlert ? <Alert severity={severity}>{response}</Alert> : <></>}
      </div>
      <Footer />
    </main>
  );
}
